import { template as template_7816f8ce6dfe4759b5ba9661f59e2241 } from "@ember/template-compiler";
const WelcomeHeader = template_7816f8ce6dfe4759b5ba9661f59e2241(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
