import { template as template_f447a902d3a740688f983bc2041ce924 } from "@ember/template-compiler";
const FKText = template_f447a902d3a740688f983bc2041ce924(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
