import { template as template_89a2dc254607401ea5e5591a31113f62 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_89a2dc254607401ea5e5591a31113f62(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
