import { template as template_cb42fc3b0c864fba8a84545e36e62bca } from "@ember/template-compiler";
const FKLabel = template_cb42fc3b0c864fba8a84545e36e62bca(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
