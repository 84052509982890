import { template as template_254b2e75e68b4a139994eeda2e519d00 } from "@ember/template-compiler";
const SidebarSectionMessage = template_254b2e75e68b4a139994eeda2e519d00(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
